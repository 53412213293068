import React from "react";
import { render } from "react-dom";
import "./index.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import WelcomePage from "./routes/welcome/WelcomePage";
import PoliticiansPage from "./routes/politician/PoliticianPage";
import styled from "@emotion/styled";
import CustomNavbar from "./components/CustomNavbar";
import { createTheme, ThemeProvider } from "@mui/material";

const theme = createTheme({
  palette: {
    primary: {
      main: `#264E70`,
    },
  },
});

const StyledApp = styled.div({
  minHeight: `100vh`,
  display: `flex`,
  flexDirection: `column`,
  background: theme.palette.primary.main,
  backgroundSize: `100vw 100vh`,
  backgroundRepeat: `no-repeat`,
  backgroundAttachment: `fixed`,
});

const rootElement = document.getElementById(`root`);
render(
  <ThemeProvider theme={theme}>
    <StyledApp>
      <BrowserRouter>
        <CustomNavbar />
        <Routes>
          <Route path="/" element={<WelcomePage />} />
          <Route path="politicians" element={<PoliticiansPage />} />
        </Routes>
      </BrowserRouter>
    </StyledApp>
  </ThemeProvider>,
  rootElement
);
