import { ChangeEvent, useEffect, useState } from "react";
import { getOffices } from "../../api/ClearVotteApi";
import { Box, IconButton, TextField } from "@mui/material";
import { OfficeWrapper } from "../../api/dto/OfficeWrapper";
import { toOfficeCards } from "./component/OfficeCard";
import { SearchOutlined } from "@mui/icons-material";

const PoliticianPage = () => {
  const defaultAddress = ``;

  const [offices, setOffices] = useState<Array<OfficeWrapper>>([]);
  const [address, setAddress] = useState<string>(defaultAddress);
  const [addressError, setAddressError] = useState<boolean>(false);

  const fetchOffices = () => {
    getOffices(address)
      .then((data) => {
        setAddressError(false);
        setOffices(data);
      })
      .catch(() => {
        setAddressError(true);
      });
  };

  useEffect(() => {
    fetchOffices();
  }, []);

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setAddress(event.target.value);
  };

  const handleSubmit = () => {
    fetchOffices();
  };

  const OfficesWrapper = () => {
    if (addressError) {
      return (
        <p style={{ color: `white` }}>
          Oops! That address doesn&apos;t look right.
        </p>
      );
    } else {
      return <div style={{ margin: `1rem` }}>{offices.map(toOfficeCards)}</div>;
    }
  };

  return (
    <div
      id={`politician-page`}
      style={{
        alignItems: `center`,
        display: `flex`,
        flexDirection: `column`,
        textAlign: `center`,
      }}
    >
      <Box
        sx={{
          width: `50%`,
          justifyContent: `center`,
        }}
        onSubmit={handleSubmit}
      >
        <div>
          <TextField
            label={`Address`}
            sx={{
              input: { color: `black` },
              backgroundColor: `white`,
            }}
            onChange={handleChange}
            onKeyPress={(e) => {
              if (e.key === `Enter`) {
                handleSubmit();
              }
            }}
            InputProps={{
              endAdornment: (
                <IconButton>
                  <SearchOutlined />
                </IconButton>
              ),
            }}
          />
        </div>
        <OfficesWrapper />
      </Box>
    </div>
  );
};

export default PoliticianPage;
