import { StyledH1 } from "../../styling/StyledH1";
import { CenteredDiv } from "../../styling/CenteredDiv";

const WelcomePage = function app() {
  return (
    <CenteredDiv>
      <StyledH1>Welcome!</StyledH1>
      <img
        style={{ height: `20rem` }}
        src={`https://images.run3wide.com/clear-vote/logos/clear_vote_logo_white.png`}
        alt={`Clear Vote logo`}
      />
    </CenteredDiv>
  );
};

export default WelcomePage;
