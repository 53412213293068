import { DetailedCivicInfoWrapper } from "./dto/DetailedCivicInfoWrapper";
import { OfficeWrapper } from "./dto/OfficeWrapper";

const HOST = `https://clear-vote.gcp-run3wide.com`;
const CLEAR_VOTE_API_HOST = `${HOST}/clear-vote`;

export async function getOffices(
  address: string
): Promise<Array<OfficeWrapper>> {
  const url =
    `${CLEAR_VOTE_API_HOST}/rest/civic-info/detailed?` +
    new URLSearchParams({
      address: address,
      isDetailed: `true`,
    }).toString();
  return fetch(url)
    .then((response) => {
      if (!response.ok) {
        return Promise.reject(`Address "${address}" could not be parsed.`);
      }
      return response.json();
    })
    .then((wrapper: DetailedCivicInfoWrapper) => wrapper.offices);
}
