import { OfficeWrapper } from "../../../api/dto/OfficeWrapper";
import { PoliticiansComponent } from "./PoliticiansComponent";
import { CardContent, Typography } from "@mui/material";
import React from "react";

export const OfficeCard = (office: OfficeWrapper) => {
  return (
    <div>
      <CardContent>
        <Typography sx={{ fontSize: `0.8rem` }} color={`#E4FDE1`}>
          {office.name}
        </Typography>
        {PoliticiansComponent(office.politicians)}
      </CardContent>
    </div>
  );
};

export const toOfficeCards = (office: OfficeWrapper) => OfficeCard(office);
