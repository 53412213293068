import { PoliticianWrapper } from "../../../api/dto/PoliticianWrapper";
import { Typography } from "@mui/material";

export const PoliticiansComponent = (
  officePoliticians: Array<PoliticianWrapper>
) => {
  return <div>{officePoliticians.map(toPoliticianTypography)}</div>;
};

const toPoliticianTypography = (politician: PoliticianWrapper) => {
  return (
    <Typography
      sx={{ color: `white`, fontSize: `0.7rem` }}
      key={politician.name}
    >
      {politician.name}
    </Typography>
  );
};
